var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',[_c('v-card',[_c('v-card-title',[_vm._v("История звонков")]),_c('v-card-actions',[_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.width < 480 ? 6 : 1}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","nudge-bottom":"35","fixeds":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"width":"150px"},attrs:{"outlined":"","value":_vm.$root.dateToRus(_vm.filters.from),"readonly":""}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","first-day-of-week":"1","locale":"ru","reactive":true},on:{"change":function($event){return _vm.reloadTable()}},model:{value:(_vm.filters.from),callback:function ($$v) {_vm.$set(_vm.filters, "from", $$v)},expression:"filters.from"}})],1)],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.width < 480 ? 6 : 1}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","nudge-bottom":"35","fixeds":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","value":_vm.$root.dateToRus(_vm.filters.to),"readonly":""}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","first-day-of-week":"1","locale":"ru","reactive":true},on:{"change":function($event){return _vm.reloadTable()}},model:{value:(_vm.filters.to),callback:function ($$v) {_vm.$set(_vm.filters, "to", $$v)},expression:"filters.to"}})],1)],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"hover":"","items":_vm.rows,"headers":_vm.headers,"item-key":"id","loading":_vm.loadingTable,"footer-props":{'items-per-page-options':[-1]},"no-data-text":"Список пуст","loading-text":"Загрузка...","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('tr',[_c('td',[_vm._v(_vm._s(index + 1)+" "),(item.record)?_c('v-icon',{attrs:{"color":"blue"},on:{"click":function($event){return _vm.$eventBus.$emit('playAudio', item.record)}}},[_vm._v(" mdi-play-circle-outline ")]):_vm._e()],1),_c('td',[_vm._v(" "+_vm._s(item.datetime_createObj.time)+" "+_vm._s(_vm.$root.dateToRus(item.datetime_createObj.date, 1)))]),_c('td',[(Number(item.datetime_start))?[_vm._v(" "+_vm._s(item.datetime_startObj.time)+", через "+_vm._s(item.datetime_start - item.datetime_create)+" с ")]:_vm._e()],2),_c('td',[_vm._v(" "+_vm._s(item.datetime_endObj.time)+" ")]),_c('td',[(parseInt(item.datetime_end))?[_vm._v(" "+_vm._s(_vm.$root.printTimeDiff(item.datetime_end - (item.datetime_start * 1 || item.datetime_create)))+" ")]:_vm._e()],2),_c('td',[_c('v-chip',{staticStyle:{"color":"white"},attrs:{"small":"","color":item.color}},[_vm._v(" "+_vm._s(item.statusStr)+" ")])],1),_c('td',[(!item.client || item.callDirection!=='incoming')?[_vm._v(_vm._s(item.phone_from))]:_vm._e(),(item.callDirection==='incoming')?[(item.client)?_c('ClientLink',{attrs:{"value":item.client}}):_vm._e(),(item.cleaner)?_c('CleanerLink',{attrs:{"value":item.cleaner}}):_vm._e()]:_vm._e()],2),_c('td',{staticStyle:{"min-width":"170px"}},[(!item.client || item.callDirection!=='outgoing')?[_vm._v(_vm._s(item.phone_to))]:_vm._e(),(item.callDirection==='outgoing')?[(item.client)?_c('ClientLink',{attrs:{"value":item.client}}):_vm._e(),(item.cleaner)?_c('CleanerLink',{attrs:{"value":item.cleaner}}):_vm._e()]:_vm._e()],2),_c('td',{staticStyle:{"max-width":"300px"}},[(item.utm)?[_vm._l((item.utm.utm),function(val,key){return _c('div',[_vm._v(_vm._s(key)+": "),_c('span',{staticStyle:{"color":"#2196f3"}},[_vm._v(_vm._s(val))])])}),(item.utm.$data && item.utm.$data.first_page)?_c('div',[_vm._v("Страница входа: "+_vm._s(item.utm.$data.first_page.url))]):_vm._e()]:_vm._e()],2)])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }