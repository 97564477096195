<template>
  <v-app>
    <v-container>
      <v-card>
        <v-card-title>История звонков</v-card-title>
        <v-card-actions>
          <v-row>
            <v-col :cols="$vuetify.breakpoint.width < 480 ? 6 : 1">

              <v-menu
                  ref="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  nudge-bottom="35"
                  fixeds
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      outlined
                      :value="$root.dateToRus(filters.from)"
                      readonly
                      style="width: 150px"
                      v-bind="attrs"
                      v-on="on"
                  />
                </template>
                <v-date-picker
                    v-model="filters.from"
                    no-title
                    scrollable
                    first-day-of-week="1"
                    locale="ru"
                    :reactive="true"
                    @change="reloadTable()"
                />
              </v-menu>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.width < 480 ? 6 : 1">
              <v-menu
                  ref="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  nudge-bottom="35"
                  fixeds
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      outlined
                      :value="$root.dateToRus(filters.to)"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  />
                </template>
                <v-date-picker
                    v-model="filters.to"
                    no-title
                    scrollable
                    first-day-of-week="1"
                    locale="ru"
                    :reactive="true"
                    @change="reloadTable()"
                />
              </v-menu>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-text>
          <v-data-table
              hover
              :items="rows"
              :headers="headers"
              item-key="id"
              :loading="loadingTable"
              :footer-props="{'items-per-page-options':[-1]}"
              no-data-text="Список пуст"
              loading-text="Загрузка..."
              mobile-breakpoint="0"
          >
            <template v-slot:item="{item, index}">
              <tr>
                <td>{{ index + 1 }}
                  <v-icon v-if="item.record" @click="$eventBus.$emit('playAudio', item.record)" color="blue">
                    mdi-play-circle-outline
                  </v-icon>
                </td>
                <td> {{ item.datetime_createObj.time }} {{ $root.dateToRus(item.datetime_createObj.date, 1) }}</td>
                <td>
                  <template v-if="Number(item.datetime_start)">
                    {{ item.datetime_startObj.time }}, через {{ item.datetime_start - item.datetime_create }} с
                  </template>
                </td>
                <td>
                  {{ item.datetime_endObj.time }}
                </td>
                <td>
                  <template v-if="parseInt(item.datetime_end)">
                    {{ $root.printTimeDiff(item.datetime_end - (item.datetime_start * 1 || item.datetime_create)) }}
                  </template>
                </td>
                <td>
                  <v-chip small :color="item.color" style="color: white">
                    {{ item.statusStr }}
                  </v-chip>
                </td>
                <td>
                  <template v-if="!item.client || item.callDirection!=='incoming'">{{ item.phone_from }}</template>
                  <template v-if="item.callDirection==='incoming'">
                    <ClientLink v-if="item.client" :value="item.client"></ClientLink>
                    <CleanerLink v-if="item.cleaner" :value="item.cleaner"></CleanerLink>
                  </template>
                </td>
                <td style="min-width: 170px">
                  <template v-if="!item.client || item.callDirection!=='outgoing'">{{ item.phone_to }}</template>
                  <template v-if="item.callDirection==='outgoing'">
                    <ClientLink v-if="item.client" :value="item.client"></ClientLink>
                    <CleanerLink v-if="item.cleaner" :value="item.cleaner"></CleanerLink>
                  </template>
                </td>
                <td style="max-width: 300px">
                  <template v-if="item.utm">
                    <div v-for="(val, key) in item.utm.utm">{{key}}: <span style="color: #2196f3">{{val}}</span></div>
                    <div v-if="item.utm.$data && item.utm.$data.first_page">Страница входа: {{item.utm.$data.first_page.url}}</div>
                  </template>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>

</template>

<script>
import ClientLink from "../../components/ClientLink";
import CleanerLink from "../../components/CleanerLink";

export default {
  name: "Table",
  components: {
    CleanerLink,
    ClientLink,
  },
  data() {
    let today = this.$moment().format('YYYY-MM-DD');
    return {
      filters: {
        from: today,
        to: today,
      },
      rows: [],
      loadingTable: false,
      headers: [
        {text: '№'},
        {text: 'Поступил звонок'},
        {text: 'Начало разговора'},
        {text: 'Окончание звонка'},
        {text: 'Продол.'},
        {text: 'Статус'},
        {text: 'Кто'},
        {text: 'Кому'},
        {text: 'Метки'},
      ]
    }
  },
  methods: {
    reloadTable(mainThread = false) {
      this.$store.state.server.request('calls/get', this.filters, (data) => {
        this.rows = data.response;
        if (mainThread) setTimeout(() => {
          if (this.$router.currentRoute.path === '/calls') this.reloadTable(mainThread)
        }, 5000)
      }, () => {
        if (mainThread) setTimeout(() => {
          if (this.$router.currentRoute.path === '/calls') this.reloadTable(mainThread)
        }, 5000);
      })
    },
  },
  mounted() {
    this.reloadTable(true)
  },
}
</script>

<style scoped>

</style>
